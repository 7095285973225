/* eslint-disable no-template-curly-in-string */
import { factory, tryit } from "ui5-lib-rc";

export function createComponent(state, props, fn, { pageKey, getProps }) {
  const { text, vBox, flexBox, component } = factory(pageKey);

  const { validation } = getProps();
  const failedToLoad = tryit(() => validation.error.failedToLoad);
  const noMatchedMenu = tryit(() => validation.error.noMatchedMenu);
  const errorStack = tryit(() => validation.error.stack);
  const errorMessage = (function() {
    if (failedToLoad) {
      // window.location.reload(true);
      return "브라우저의 새로고침(F5)버튼을 눌러주세요";
    }
    if (noMatchedMenu) {
      return "해당 메뉴에 대한 접근 권한이 없습니다";
    }
    return [
      "예기치 못한 오류가 발생하였습니다.",
      "새로고침을 하고 다시 시도해도 같은 오류가 발생하면 시스템 담당자에게 전달해주시기 바랍니다."
    ].join(" ");
  })();

  const loadingVBox = vBox.set({
    name: "loading",
    settings: {
      items: [
        text.set({
          name: "notauthorized",
          text: errorMessage
        }),
        !!errorStack
          ? component.set(window.sap.m.TextArea, {
              name: "errorStack",
              value: errorStack,
              editable: false,
              width: "80vw",
              height: "10rem"
            })
          : null
      ]
    }
  });

  const loadingFlexBox = flexBox.set({
    name: "loadingFlexBox",
    settings: {
      alignItems: window.sap.m.FlexAlignItems.Center,
      justifyContent: window.sap.m.FlexJustifyContent.Center,
      fitContainer: true,
      items: [loadingVBox]
    }
  });

  const page = {
    content: [loadingFlexBox]
  };

  if (validation.hasError) {
    return page;
  }
}
