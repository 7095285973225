import produce from "immer";
import { SET_EMBED_URL } from "./actions";

export const initialState = {
  embedURL: "",
  title: "",
  parameters: []
};

export const reducer = function(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case SET_EMBED_URL:
        draft.embedURL = action.embedURL;
        draft.title = action.title;
        draft.parameters = action.parameters;
        draft.changes = action.changes;
        break;
      default:
        break;
    }
  });
};
