import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { updateExpiresAt, asyncSignOut } from "actions/user";
import { ObjectStatus, Icon, Text, ValueState } from "@ui5/webcomponents-react";

let interval;

function ExpiraryCountDown(props) {
  const { expiresAt, dispatch } = props;

  const ref = useRef(null);

  useEffect(() => {
    if (expiresAt) {
      if (interval) {
        clearInterval(interval);
        interval = undefined;
      }
      // console.log("set timer:", expiresAt);
      interval = setInterval(() => {
        const now = moment();
        const expires = moment(expiresAt);
        const diff = expires.diff(now, "seconds");

        if (diff < 0) {
          clearInterval(interval);
          interval = undefined;
          dispatch(asyncSignOut());
        } else {
          ref.current.innerHTML = new Date(diff * 1000)
            .toISOString()
            .slice(11, 19);
        }
      }, 1000);
      dispatch(updateExpiresAt(""), false);
    }
    return () => {
      if (!expiresAt) {
        // console.log("clear timer");
        clearInterval(interval);
        interval = undefined;
      }
    };
  }, [expiresAt]);

  return (
    interval && (
      <ObjectStatus
        id={"countdown-status"}
        icon={<Icon name="lateness" />}
        state={ValueState.Warning}
        // inverted={true}
        className="sapUiTinyMarginEnd"
        large={true}
      >
        {<Text id="countdown-text" ref={ref}></Text>}
      </ObjectStatus>
    )
  );
}

function mapStateToProps(state) {
  return {
    expiresAt: state.user.currentUser.expiresAt
  };
}

export default withRouter(connect(mapStateToProps)(ExpiraryCountDown));
