/* eslint-disable no-template-curly-in-string */
import { factory } from "ui5-lib-rc";

export function createComponent(state, props, fn, { pageKey }) {
  const { text, vBox, flexBox } = factory(pageKey);

  const loadingVBox = vBox.set({
    name: "loading",
    settings: {
      items: [
        text.set({
          name: "loading",
          text: "Loading"
        })
      ]
    }
  });

  const loadingFlexBox = flexBox.set({
    name: "loadingFlexBox",
    settings: {
      alignItems: window.sap.m.FlexAlignItems.Center,
      justifyContent: window.sap.m.FlexJustifyContent.Center,
      fitContainer: true,
      items: [loadingVBox]
    }
  });

  const page = {
    content: [loadingFlexBox]
  };

  return page;
}
