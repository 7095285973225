import React, { useMemo } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import { compose } from "@reduxjs/toolkit";
import { useReducerWithLogger } from "@bsgp/lib-hooks";

import { reducer, initialState } from "./reducers";
import * as actions from "./actions";
import { context } from "./context";
import Main from "./main";

function Dashboard(props) {
  const { match, history, qsConfig } = props;
  const { path, params } = match;
  const { location } = history;

  const queryParams = new URLSearchParams(location.search);

  const [_state, _dispatch] = useReducerWithLogger(reducer, initialState);

  const newQsConfig = useMemo(
    () => ({
      id: params.qsAlias,
      sessionId: queryParams.get("session"),
      type: "EmbedDashboard",
      ...qsConfig
    }),
    []
  );

  return (
    <context.Provider
      value={{
        _state,
        _dispatch
      }}
    >
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={rProps => (
            <Main
              {...rProps}
              {...props}
              title={_state.title || props.title}
              {...{
                _state,
                _dispatch,
                qsConfig: newQsConfig
              }}
            />
          )}
        />
      </Switch>
    </context.Provider>
  );
}

export default compose(withRouter)(Dashboard);

export const { getEmbedURL, embedOnDom } = actions;
