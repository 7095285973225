export const ADD_ERROR = "ADD_ERROR";
export function addError(list) {
  return {
    type: ADD_ERROR,
    list
  };
}

export const REMOVE_ERROR = "REMOVE_ERROR";
export function removeError() {
  return {
    type: REMOVE_ERROR
  };
}

export const INIT_DATA = "INIT_DATA";
export const initData = () => {
  return {
    type: INIT_DATA
  };
};

export const ADD_NOTICE = "ADD_NOTICE";
export function addNotice(list = [], ignoreIndicator = false) {
  return {
    type: ADD_NOTICE,
    ignoreIndicator,
    list
  };
}

export const REMOVE_CURRENT_NOTICE = "REMOVE_CURRENT_NOTICE";
export function removeCurrentNotice() {
  return {
    type: REMOVE_CURRENT_NOTICE
  };
}

export const CLEAR_NOTICE = "CLEAR_NOTICE";
export function clearNotice() {
  return {
    type: CLEAR_NOTICE
  };
}

export const OPEN_MESSAGE_BOX = "OPEN_MESSAGE_BOX";
export function openMessageBox(payload) {
  return {
    type: OPEN_MESSAGE_BOX,
    payload
  };
}

export const CLOSE_MESSAGE_BOX = "CLOSE_MESSAGE_BOX";
export function closeMessageBox() {
  return {
    type: CLOSE_MESSAGE_BOX
  };
}
