import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  isDialogOpen: false
};

const slice = createSlice({
  name: "FORM_AND_TABLE",
  initialState,
  reducers: {
    updateIsDialogOpen: (draft, action) => {
      draft.isDialogOpen = action.payload;
    }
  }
});

const poSlice = {
  initialState,
  reducer: slice.reducer,
  actions: slice.actions
};
export const reducer = slice.reducer;
export const actions = slice.actions;
export default poSlice;
