import store from "@bsgp/lib-api/store";
import { tryit } from "@bsgp/lib-core";

export const convertController = (controller, state, props, getProps) => {
  return Object.keys(controller).reduce((newController, argKey) => {
    if (typeof controller[argKey] === "function") {
      const fnType = (function() {
        if (props.render5Version === "20190808") {
          return typeof tryit(() => controller[argKey]({}));
        } else {
          return typeof tryit(() => controller[argKey]());
        }
      })();
      if (fnType === "function") {
        if (props.render5Version === "20190808") {
          newController[argKey] = controller[argKey]({
            state,
            props,
            getState: store.getState,
            getProps
          });
        } else {
          newController[argKey] = controller[argKey](
            state,
            props,
            store.getState,
            getProps
          );
        }
      } else {
        console.error(
          controller[argKey].name,
          "Please wrap your exported function with (state, props) =>.",
          "Otherwise it will implicitly run your business logic twice",
          "with () and (state, props) arguments."
        );

        // newController[argKey] = controller[argKey];
      }
    } else {
      newController[argKey] = controller[argKey];
    }
    return newController;
  }, {});
};
