import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { Route as WrapRoute, combineRoutes } from "components/Route";
import LcUi5 from "@bsgp/lc-ui5";
import MainPage from "components/main";
import routes from "components/rootRoutes";

const newRoutes = combineRoutes(routes);

const gotoFromOrHome = (location, currentUser) => {
  console.log("gotoFromOrHome:", currentUser);

  if (currentUser.passwordIsReset) {
    return (
      <Redirect
        push={false}
        to={{
          pathname: `/resetpassword`,
          state: location.state
        }}
      />
    );
  }

  const state = location.state;
  const search = location.search;

  if (state) {
    if (state.from && !currentUser.goHomeAtSignIn) {
      // history.push(q.from)
      if (state.from === "/gotoFromOrHome") {
        return <Redirect push={false} to={{ pathname: "/", search: search }} />;
      }
      return (
        <Redirect
          push={false}
          to={{ pathname: `${state.from}`, search: search }}
        />
      );
    } else {
      // history.push("/")

      return <Redirect push={false} to={{ pathname: "/", search: search }} />;
    }
  } else {
    return <Redirect push={false} to={{ pathname: "/", search: search }} />;
  }
};

function Routes(props) {
  const { routes, currentUser } = props;
  const env = process.env;

  return (
    <Switch>
      <Route
        path={"/gotoFromOrHome"}
        exact
        render={({ location }) => {
          return gotoFromOrHome(location, currentUser);
        }}
      />

      {routes
        .filter(({ checkAuth }) => checkAuth === true)
        .map((route, idx) => {
          const LazyComponent = route.component;
          return (
            <WrapRoute
              key={idx}
              path={route.path}
              exact={route.exact}
              component={LazyComponent}
              currentUser={currentUser}
              env={env}
              {...route.additionalProps}
            />
          );
        })}
      {routes
        .filter(({ checkAuth }) => !checkAuth)
        .map((route, idx) => {
          const LazyComponent = route.component;
          return (
            <Route
              key={idx}
              path={route.path}
              exact={route.exact}
              render={props => (
                <LazyComponent
                  {...props}
                  currentUser={currentUser}
                  env={env}
                  {...route.additionalProps}
                />
              )}
            />
          );
        })}

      <WrapRoute
        path={"/"}
        exact={false}
        component={LcUi5}
        currentUser={currentUser}
        env={env}
      />
    </Switch>
  );
  // }
}

function AuthRoutes(props) {
  const { currentUser } = props;

  return (
    <Switch>
      <Route exact path={"/"} render={rProps => <MainPage {...rProps} />} />
      <Routes routes={newRoutes} currentUser={currentUser} />
    </Switch>
  );
}

export default compose(connect(), withRouter)(AuthRoutes);
