import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";

import { FormTable, formTable, ft } from "@bsgp/form-and-table";

import * as controller from "./index.controller";
import { useReducerWithLogger } from "@bsgp/lib-hooks";
import { reducer, initialState } from "./slice";

function FormTableTest(props) {
  const [_state, _dispatch] = useReducerWithLogger(
    reducer,
    initialState,
    false
  );
  const { isDialogOpen } = _state;

  const builder = formTable({});

  builder.addDialog("toastDialog", {
    width: "25rem",
    isOpen: isDialogOpen,
    onClose: fn => fn.openDialog(false),
    ftData: formTable({})
      .addForm("dialogForm", {
        sizeV2: true,
        compactSize: true
      })
      .addContainer("DialogContainer")
      .addField("triggerErrorToast", {
        label: "",
        component: ft.Button({
          onPress: fn => fn.triggerError,
          properties: {
            text: "Toast 띄우기"
          }
        })
      })
      .done(),
    properties: { width: "25rem" }
  });

  builder.addFooterButton("openDialog", {
    text: "다이얼로그 오픈",
    onPress: fn => fn.openDialog(true)
  });

  const title = "Test OpenUi5";

  return (
    <FormTable
      {...props}
      {...{ _state, _dispatch }}
      fn={controller}
      title={title}
      data={builder.done()}
    />
  );
}

function mapStateToProps(state, ownProps) {
  return {
    render5Version: "20190808",
    currentUser: state.user.currentUser
  };
}

export default compose(connect(mapStateToProps), withRouter)(FormTableTest);
