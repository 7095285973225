import React, { useState, useEffect } from "react";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import { FormTable, formTable, ft } from "@bsgp/form-and-table";

const Privacy = props => {
  // const [termsOfService, setTermsOfService] = useState(false);
  const [mainText, setMainText] = useState("");

  useEffect(() => {
    const privacyPath = `${window.location.pathname.replace(
      "/privacy",
      ""
    )}/privacy_20230401.txt?v=20230314`;
    console.log("privacyPath:", privacyPath);
    fetch(privacyPath)
      .then(res => res.text())
      .then(text => {
        setMainText(text);
      });
  });

  const builder = formTable({ hCenter: true });
  builder
    .addForm("termsOfServiceForm", {
      sizeV2: true,
      columnsM: 1
    })
    .addContainer("termsOfServiceContainer")
    // .addField("termsOfServiceTitle", {
    //   value: "이용역관",
    //   component: ft.Text({
    //     properties: { fontSize: "medium", fontWeight: "900" }
    //   })
    // })
    .addField("termsOfServiceTextArea", {
      value: mainText,
      component: ft.TextArea({
        properties: { editable: false, width: "80vw", height: "80vh" }
      })
    });
  // .addField("termsOfServiceCheckbox", {
  //   label: "이용약관에 동의합니다.",
  //   value: termsOfService,
  //   component: ft.CheckBox({
  //     onChange: () => () => setTermsOfService(!termsOfService)
  //   })
  // });

  // .addField("termsOfPrivateInfoTitle", {
  //   value: "개인정보 수집 및 이용",
  //   component: ft.Text({
  //     properties: { fontSize: "medium", fontWeight: "900" }
  //   })
  // })
  // .addField("termsOfPrivateInfoTextArea", {
  //   value: text.reduce((pre, cur) => pre + cur, ""),
  //   component: ft.TextArea({
  //     properties: { editable: false, height: "30vh" }
  //   })
  // })
  // .addField("termsOfPrivateInfoCheckbox", {
  //   label: "개인정보 수집 및 이용에 동의합니다.",
  //   value: state.termsOfPrivate,
  //   component: ft.CheckBox({
  //     onChange: () => termsOfService("termsOfPrivate")
  //   })
  // })
  // .addField("signUpButton", {
  //   label: "",
  //   value: ["회원가입", "일반화원가입"],
  //   component: [
  //     ft.Button("specialAccount", {
  //       onPress: fn => () => fn.moveToImpactus(state)
  //     }),
  //     ft.Button("generalAccount", {
  //       onPress: fn => () => fn.moveToImpactus(state)
  //     })
  //   ]
  // });

  return (
    <FormTable
      {...props}
      fn={{}}
      title={"개인정보처리방침"}
      data={builder.done()}
    />
  );
};

const mapStateToProps = (state, ownProps) => ({
  render5Version: "20190808",
  currentUser: state.user.currentUser
});
export default compose(connect(mapStateToProps))(Privacy);
