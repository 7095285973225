/* eslint-disable id-length */
// import { newProcess } from "ws-process";
import { apiHub } from "@bsgp/lib-api";
import { createEmbeddingContext } from "amazon-quicksight-embedding-sdk";

export const SET_EMBED_URL = "[QuickSight] SET_EMBED_URL";
export function setEmbedURL(data) {
  return {
    type: SET_EMBED_URL,
    ...data
  };
}

export const getEmbedURL = (params, callback) => dispatch => {
  // console.log("params:", params);
  return apiHub.get(
    "/g/quicksight",
    {
      dashboardAlias: params.id,
      embedType: params.type,
      sessionId: params.sessionId,
      doNotAddParamsToUrl: true
    },
    callback
  );
  // return newProcess("Get Embed URL", { showDescription: true })
  //   .newRequest({
  //     requestHandler: reqEmbedURL,
  //     params,
  //     responseHandler: resEmbedURL,
  //     callback
  //   })
  //   .start();
};

// const reqEmbedURL = ({ params }) => dispatch => {
//   return {
//     version: "20190625",
//     action: "rest",
//     subAction: "quicksight",
//     description: "대시보드 조회를 요청합니다",
//     httpMethod: "GET",
//     params: {
//       Type: params.type,
//       ID: params.id
//     }
//   };
// };

// const resEmbedURL = ({ jsonResponse }) => dispatch => {
//   // console.log(jsonResponse);
//   const { embedURL } = jsonResponse;

//   if (embedURL) {
//     return [true, jsonResponse];
//   } else {
//     return [false, jsonResponse];
//   }
// };

export const embedOnDom = domId => {
  const embeddingContext = createEmbeddingContext();

  // const embeddingContext = await createEmbeddingContext({
  //   onChange: (changeEvent, metadata) => {
  //     console.log("Context received a change", changeEvent, metadata);
  //   }
  // });

  const frameOptions = {
    url: "<YOUR_EMBED_URL>",
    container: "#".concat(domId),
    height: "100%",
    width: "100%",
    resizeHeightOnSizeChangedEvent: true,
    withIframePlaceholder: true,
    onChange: (changeEvent, metadata) => {
      console.log("onChange:", changeEvent, metadata);
      switch (changeEvent.eventName) {
        case "FRAME_NOT_CREATED": {
          document.getElementById(domId).innerHTML =
            "대시보드 로딩 실패, 새로고침하여 다시 시도해보시기 바랍니다";
          break;
        }
        case "FRAME_STARTED": {
          console.log("Do something when the experience frame starts to load.");
          break;
        }
        case "FRAME_MOUNTED": {
          console.log("Do something when the experience frame is mounted.");
          break;
        }
        case "FRAME_LOADED": {
          console.log("Do something when the experience frame is loaded.");
          break;
        }
        default: {
          console.log(
            "Unexpected changeEvent.eventName:",
            changeEvent.eventName
          );
          break;
        }
      }
    }
  };

  const contentOptions = {
    // parameters: [
    //   {
    //     Name: "country",
    //     Values: ["United States"]
    //   },
    //   {
    //     Name: "states",
    //     Values: ["California", "Washington"]
    //   }
    // ],
    locale: "ko-KR",
    // sheetOptions: {
    //   initialSheetId: "<YOUR_SHEETID>",
    //   singleSheet: false,
    //   emitSizeChangedEventOnSheetChange: false
    // },
    toolbarOptions: {
      export: true,
      undoRedo: true,
      reset: true
    },
    attributionOptions: {
      overlayContent: false
    },
    onMessage: async (messageEvent, experienceMetadata) => {
      console.log("onMessage:", messageEvent, experienceMetadata);
      switch (messageEvent.eventName) {
        case "CONTENT_LOADED": {
          console.log(
            "All visuals are loaded. The title of the document:",
            messageEvent.message.title
          );
          break;
        }
        case "ERROR_OCCURRED": {
          console.log(
            "Error occurred while rendering the experience. Error code:",
            messageEvent.message.errorCode
          );
          break;
        }
        case "PARAMETERS_CHANGED": {
          console.log(
            "Parameters changed. Changed parameters:",
            messageEvent.message.changedParameters
          );
          break;
        }
        case "SELECTED_SHEET_CHANGED": {
          console.log(
            "Selected sheet changed. Selected sheet:",
            messageEvent.message.selectedSheet
          );
          break;
        }
        case "SIZE_CHANGED": {
          console.log("Size changed. New dimensions:", messageEvent.message);
          break;
        }
        case "MODAL_OPENED": {
          window.scrollTo({
            top: 0 // iframe top position
          });
          break;
        }
        default: {
          console.log(
            "Unexpected messageEvent.eventName:",
            messageEvent.eventName
          );
          break;
        }
      }
    }
  };

  return (embedURL, options = {}) => {
    const { qsConfig, toolbarOptions, parameters } = options;

    frameOptions.url = embedURL;

    if (toolbarOptions) {
      contentOptions.toolbarOptions = {
        ...contentOptions.toolbarOptions,
        ...toolbarOptions
      };
    }

    if (parameters) {
      console.log("parameters:", parameters);
      contentOptions.parameters = parameters;
    }

    return embeddingContext
      .then(qsContext => {
        if (qsConfig.type === "EmbedConsole") {
          return qsContext.embedConsole(frameOptions, contentOptions);
        }
        return qsContext.embedDashboard(frameOptions, contentOptions);
      })
      .then(qsExp => {
        window.qsExp = qsExp;
        return qsExp;
      });
  };
};
