import { withRouter } from "react-router-dom";
import React, { useEffect } from "react";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";

import { render5 } from "@bsgp/lib-hoc";
import { getEmbedURL, setEmbedURL, embedOnDom } from "../actions";

import * as controller from "./index.controller";
import * as ui5 from "./index.ui5";

const embedder = embedOnDom("qsEmbed");

function Main(props) {
  const {
    _state,
    dispatch,
    _dispatch,
    qsConfig
    // parameters,
    // currentUser
  } = props;
  const { embedURL, parameters, changes } = _state;

  useEffect(() => {
    dispatch(
      getEmbedURL(qsConfig, {
        afterSucceed: data => {
          if (data) {
            _dispatch(setEmbedURL(data));
          }
        }
      })
    );
    return function clear() {
      // _dispatch(removeAllPR());
    };
  }, []);

  useEffect(() => {
    if (!embedURL) {
      return;
    }
    let looper;
    embedder(embedURL, {
      qsConfig,
      parameters: parameters || [],
      changes
      // [
      //   {
      //     Name: "scope",
      //     Values: [[currentUser.systemID, currentUser.partnerID].join("@")]
      //   }
      // ].concat(parameters || [])
    }).then(async qsExp => {
      if (changes) {
        let idx = 0;
        looper = setInterval(() => {
          qsExp.setParameters(changes.list[idx]);
          console.log("idx parameter changed:", idx);
          idx += 1;
          if (idx === changes.list.length) {
            idx = 0;
          }
        }, 5000);
      }
    });

    return () => {
      if (looper) {
        clearInterval(looper);
      }
    };
  }, [embedURL, qsConfig, parameters]);

  // return (
  // <Iframe
  //   url={embedURL}
  //   width="100%"
  //   height="100%"
  //   id="myId"
  //   loading="Loading"
  //   className="myClassname"
  //   display="initial"
  //   position="relative"
  // />
  // );
  return (
    <div
      id="qsEmbed"
      style={{ height: "100%", width: "100%" }}
      className="renderJSX"
    ></div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    render5Version: "20190808"
  };
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  // withReducer("purchaseOrderCreation", purchaseOrderCreation),
  render5(ui5, controller, { renderJSX: true })
)(Main);
