import { apiHub } from "@bsgp/lib-api";
import Config from "@bsgp/lib-config";
import { tryit } from "@bsgp/lib-core";
import { addError, addNotice } from "actions/ui5";

const renderNoticeDialog = (props, alert = false, getForce = false) => {
  return async (dispatch, getState) => {
    const isSetNotice = tryit(
      () => Config.ref.merged[Config.id.UI].Common.isSetNotice
    );
    if (!isSetNotice) {
      if (alert) dispatch(addError("공지사항 설정이 필요합니다."));
      return;
    }
    const path = "/cc/activated_notice";
    if (props.history.location.pathname.includes(path)) {
      return;
    }
    // 공지된 사항이 있는 경우에만 다이얼로그를 띄움
    const { systemID, partnerID, id } = getState().user.currentUser;
    const sys_id = [systemID, partnerID].join("@");
    const params = {
      InterfaceId: "IF-NT-002",
      Function: { UserId: id },
      Data: { user_id: id, sys_id, getForce }
    };

    const activatedNotice = await apiHub
      .post("/g/notice", params)
      .then(res => res.data.cbData)
      .catch(() => ({
        E_STATUS: "E",
        E_MESSAGE: "Get Notice Failed",
        list: []
      }));

    if (activatedNotice.E_STATUS !== "S" || activatedNotice.list.length === 0) {
      if (alert) {
        dispatch(addError(activatedNotice.E_MESSAGE));
      }
    } else {
      const { list, ignoreIndicator } = activatedNotice;
      dispatch(addNotice(list, ignoreIndicator));
    }
  };
};

export default renderNoticeDialog;
