import {
  fetchPRList,
  fetchSupplier,
  updateSupplierName,
  createPurchaseOrder,
  cancelPR,
  bundleItems,
  changeBundleInput,
  closeBundle
} from "../actions";
import { tryit } from "ui5-lib-rc";
import { removeAllPR } from "../actions";
import { getString } from "@bsgp/lib-date";
import { addError } from "actions/ui5";

export const onFetchSupplierName = ({ props }) => oEvent => {
  const { supplier } = oEvent
    .getSource()
    .getBindingContext()
    .getObject();
  const { dispatch, _dispatch } = props;

  dispatch(
    fetchSupplier(
      { id: supplier.id },
      {
        afterSucceed: body => {
          const suppliers = tryit(() => body.d.results);
          if (suppliers && suppliers.length === 1) {
            const supplier = suppliers[0];
            _dispatch(
              updateSupplierName(
                supplier.InternalID,
                supplier.BusinessPartnerFormattedName
              )
            );
          }
        }
      }
    )
  );
};

export const onSearch = ({ props }) => (getInputs, $skip) => oEvent => {
  const { dispatch, _dispatch, _state } = props;
  const { $top, queryBy } = _state;

  const oDateRange = getInputs();

  const dateFrom = oDateRange.getDateValue();
  const dateTo = oDateRange.getSecondDateValue();

  if (!dateFrom) {
    dispatch(addError("기간을 선택하세요"));
    return;
  }

  if (!$skip) {
    _dispatch(removeAllPR());
  }

  dispatch(
    fetchPRList({
      dateFrom: getString(dateFrom, "begin"),
      dateTo: getString(dateTo, "end"),
      $top,
      $skip,
      queryBy,
      _dispatch
    })
  );
};

export const onNaviToBundle = ({ props }) => oTable => () => {
  const { _dispatch, employee } = props;

  const selectedItems = oTable.getSelectedItems();
  const dataItems = selectedItems.map(oItem =>
    oItem.getBindingContext().getObject()
  );

  _dispatch(bundleItems(dataItems, employee));
};

export const onBundleInputChanged = ({ props }) => (
  fieldName,
  fieldType
) => oEvent => {
  const { _dispatch } = props;
  const { newValue } = oEvent.getParameters();
  const oSource = oEvent.getSource();
  const row = oSource.getBindingContext().getObject();
  _dispatch(changeBundleInput(row.seq - 1, fieldName, newValue, fieldType));
};

export const onCloseBundle = ({ props }) => oEvent => {
  const { _dispatch } = props;
  _dispatch(closeBundle());

  const oDialog = oEvent.getSource().getParent();
  tryit(() => oDialog.isOpen() && oDialog.close());
};

export const onBundle = ({ props }) => (oTable, oPRTable) => oEvent => {
  const { dispatch, _dispatch } = props;

  const poItems = oTable.getModel().getData().items;

  const [isValid, poHeader] = poItems.reduce(
    (result, item) => {
      if (!result[0]) {
        return result;
      }

      if (item.bundleQuantity.number <= 0) {
        dispatch(addError("수량이 0과 같거나 작으면 안됩니다"));
        return [false, {}];
      }

      if (!item.supplier.id) {
        dispatch(addError("공급업체는 필수입력사항입니다"));
        return [false, {}];
      }

      if (!item.employee.id) {
        dispatch(addError("구매담당자는 필수입력사항입니다"));
        return [false, {}];
      }

      const poList = result[1];
      const matchedPO = poList.find(
        po => po.supplier === item.supplier.id && po.buyer === item.buyer.id
      );
      if (matchedPO) {
        matchedPO.items.push(item);
      } else {
        const newPO = {
          currency: item.currency,
          supplier: item.supplier.id,
          employee: item.employee.id,
          buyer: item.buyer.id,
          items: [item]
        };
        poList.push(newPO);
      }

      return result;
    },
    [true, []]
  );

  if (isValid) {
    const oDialog = oEvent.getSource().getParent();
    const oBeginButton = oDialog.getBeginButton();
    const oEndButton = oDialog.getEndButton();

    oBeginButton.setEnabled(false);
    oEndButton.setEnabled(false);
    oTable.setBusy(true);

    dispatch(
      createPurchaseOrder(poHeader, {
        afterSucceed: poID => {
          const oItems = oPRTable.getSelectedItems();
          const selectedPRList = oItems.map(oItem => {
            const oSelectControl = oItem.getMultiSelectControl();
            if (oSelectControl) {
              oSelectControl.setEnabled(false);
            }

            return oItem.getBindingContext().getObject();
          });
          dispatch(
            cancelPR(selectedPRList, _dispatch, () => {
              dispatch(
                addError({
                  type: "S",
                  message: `구매오더 ${poID}이 생성되었습니다`
                })
              );

              oBeginButton.setEnabled(true);
              oEndButton.setEnabled(true);
              oTable.setBusy(false);
              oDialog.isOpen() && oDialog.close();
            })
          );
        },
        always: cbData => {
          if (cbData === undefined) {
            oBeginButton.setEnabled(true);
            oEndButton.setEnabled(true);
            oTable.setBusy(false);
          }
        }
      })
    );
  }
};
