import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";

import render5 from "hoc/render5";
import withReducer from "hoc/withReducer";
import adir from "reducers/adir";
import { doingValidation, doneValidation } from "actions/adir";
import { createComponent } from "components/manage_adir/ValidationError.ui5";

class ValidationError extends React.Component {
  constructor(props) {
    super(props);

    const { dispatch } = props;
    dispatch(doingValidation(), false);
  }

  // static getDerivedStateFromError(error) {
  //   // Update state so the next render will show the fallback UI.
  //   // return { hasError: true };
  // }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);

    // this.setState({
    //   hasError: true
    // });

    const { dispatch } = this.props;
    if (error) {
      dispatch(doneValidation({ hasError: true, error }), false);
    }

    console.error("error, errorInfo", error, errorInfo);
  }

  componentDidMount() {
    const { dispatch, validation } = this.props;
    if (!validation.hasError) {
      dispatch(doneValidation({ hasError: false }), false);
    }
  }

  render() {
    if (this.props.validation.hasError) {
      // You can render any custom fallback UI
      // return <h1>Something went wrong.</h1>;

      return true;
    }

    return this.props.children;
  }
}

function mapStateToProps(state, ownProps) {
  return {
    render5Version: "20190808",
    validation: state.adir.validation || {}
  };
}

const fn = {};

export default compose(
  connect(mapStateToProps),
  withRouter,
  withReducer("adir", adir),
  render5(createComponent, fn)
)(ValidationError);
