import { addError } from "actions/ui5";
import { actions } from "./slice";

export const openDialog = ({ getProps }) => value => () => {
  const { _dispatch } = getProps();
  _dispatch(actions.updateIsDialogOpen(value));
};

export const triggerError = ({ getProps }) => () => {
  const { dispatch } = getProps();
  dispatch(addError({ message: "에러 Toast 오픈", type: null }));
};
