import React, { Component } from "react";
import store from "store";

const withReducer = (key, reducer) => WrappedComponent => {
  store.injectReducer(key, reducer);
  return class extends Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};

export default withReducer;
