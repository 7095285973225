import React from "react";
import { connect } from "react-redux";
import {
  MessageBox as MessageBoxComponent,
  MessageBoxTypes
} from "@ui5/webcomponents-react";
import { CLOSE_MESSAGE_BOX } from "actions/ui5";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  messageBox: {
    "& div[slot='footer'] ui5-button": {
      padding: 0
    }
  }
});

const MessageBox = props => {
  const { messageBox, dispatch } = props;
  const classes = useStyles();

  const onConfirm = messageBox.yes || (() => {});
  const onAbort = messageBox.no || (() => {});

  return (
    <MessageBoxComponent
      open={!!messageBox.text}
      type={MessageBoxTypes.Confirm}
      onClose={event => {
        if (event.detail.action === "OK") {
          onConfirm(messageBox.params);
        } else if (event.detail.action === "Cancel") {
          onAbort(messageBox.params);
        }
        dispatch({ type: CLOSE_MESSAGE_BOX });
      }}
      className={classes.messageBox}
    >
      {messageBox.text || ""}
    </MessageBoxComponent>
  );
};

function mapStateToProps(state) {
  return {
    messageBox: state.ui5.messageBox
  };
}

export default connect(mapStateToProps)(MessageBox);
