import React from "react";
import { Route, Switch } from "react-router-dom";
import routesConfig from "./routesConfig";
import { Button } from "@ui5/webcomponents-react";

function TestPages(props) {
  const { path } = props.match;

  return (
    <Switch>
      <Route
        exact
        path={path}
        render={props => <TestPageList {...{ ...props, path }} />}
      />
      {routesConfig.map(route => (
        <Route
          exact
          key={route.name}
          path={`${path}${route.path}`}
          render={props => <route.component {...{ ...props, path }} />}
        />
      ))}
    </Switch>
  );
}

function TestPageList(props) {
  const { history, path } = props;

  return (
    <div
      style={{
        width: "100dvw",
        height: "100dvh",
        display: "flex",
        wrap: "auto",
        padding: "10px"
      }}
    >
      {routesConfig.map(route => (
        <Button
          id={route.name}
          name={route.name}
          key={"button_" + route.name}
          className="button_for_ui_test"
          onClick={() => {
            history.push(`${path}${route.path}`);
          }}
        >
          {route.name}
        </Button>
      ))}
    </div>
  );
}

export default TestPages;
