import React, { lazy, Fragment } from "react";
import { Route } from "react-router-dom";

const validateAuth = (allPaths, targetPath) => {
  if (!allPaths) {
    return false;
  }
  const matchedPath = allPaths.find(path => {
    const regTargetPath =
      path.url &&
      new RegExp(
        "^" +
          path.url.replace(/\/{0,1}$/, "").replace(/\//g, "\\/") +
          "(([/]{1}.{1,})|([/]{1})|(.{0}))$"
      );
    if (regTargetPath) {
      return regTargetPath.test(targetPath);
    } else {
      return false;
    }
  });
  return !!matchedPath;
};

const wrapLazy = importFunction => {
  return lazy(() => {
    return new Promise((resolve, reject) => {
      return importFunction()
        .then(data => resolve(data))
        .catch(error => {
          reject({ failedToLoad: true, error });
        });
    });
  });
};

const WrapRoute = props => {
  const { path, component: Component, exact = true, children, ...rest } = props;
  const { currentUser } = rest;

  if (!path) {
    return false;
  }
  return (
    <Route
      exact={exact}
      path={path}
      render={props => {
        const { location } = props;
        // console.log(location);
        if (validateAuth(currentUser.flattedPaths, location.pathname)) {
          return <Component {...props} {...rest} />;
        } else {
          const errorMessage =
            "There is no matched path assigned to the current user";
          // const error = new Error(errorMessage);
          const error = { noMatchedMenu: true, message: errorMessage };
          // return Promise.reject(error);
          throw error;
        }
      }}
    />
  );
};

function RouteWithSubRoutes(props) {
  const { routes = [], path, component, ...rest } = props;
  console.log("path:", path);
  return (
    <Fragment>
      <WrapRoute path={path} component={component} {...rest} />
      {routes.map((route, idx) => {
        return <RouteWithSubRoutes key={idx} {...route} {...rest} />;
      })}
    </Fragment>
  );
}

function combineRoutes(routes) {
  return routes.map(
    ({ path, component, exact = true, checkAuth = true, ...rest }) => ({
      path,
      component: wrapLazy(component),
      exact,
      checkAuth,
      ...rest
    })
  );
}

export {
  wrapLazy as lazy,
  WrapRoute as Route,
  RouteWithSubRoutes as Routes,
  combineRoutes
};
