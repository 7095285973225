import React, { Component } from "react";
import { connect } from "react-redux";
import { isObject, makeid, tryit } from "@bsgp/lib-core";
import store from "@bsgp/lib-api/store";
import { newComponent, byId, customData } from "ui5-lib-rc";
import { closeRootNotice } from "actions/user";
import { titleRef } from "title";
import * as modules from "./modules";
// import { clearTransaction } from "actions/transaction";

const pageKeyList = [];

function generateNewPageKey(WrappedComponent, locationKey) {
  const newPageKey = {
    fn: WrappedComponent,
    componentName: WrappedComponent.name,
    locationKey,
    keyString: [makeid(5, "a"), "_"].join("")
  };
  pageKeyList.push(newPageKey);
  return newPageKey;
}

const hoc = (ui5Creator, ui5Controller, extraArgs) => WrappedComponent => {
  class Render5 extends Component {
    constructor(props) {
      super(props);

      const locationKey = props.location.key;
      const matchedPageKey =
        pageKeyList.find(obj => obj.locationKey === locationKey) ||
        generateNewPageKey(WrappedComponent, locationKey);

      this.pageKey = matchedPageKey.keyString;
    }

    // openTransactionPopover = transactionList => oEvent => {
    //   newComponent(window.sap.m.Popover, {
    //     name: "transactionPopover",
    //     altPrefix: "",
    //     settings: {
    //       title: "Transaction",
    //       placement: "Bottom",
    //       endButton: newButton({
    //         name: "clearAllHistory",
    //         settings: {
    //           text: "Clear",
    //           press: oEvent => {
    //             const { dispatch } = this.props;
    //             dispatch(clearTransaction());
    //             if (
    //               transactionList.findIndex(item =>
    // item.statusCode !== "0") >
    //               -1
    //             ) {
    //               byId("transactionPopover").close();
    //             }
    //           }
    //         }
    //       }),
    //       content: newComponent(window.sap.m.List, {
    //         name: "popoverList",
    //         altPrefix: "",
    //         settings: {
    //           items: transactionList.length
    //             ? transactionList.map((item, index) =>
    //                 newComponent(window.sap.m.NotificationListItem, {
    //                   name: `${item.id}_info_${index}`,
    //                   altPrefix: "",
    //                   title: item.text,
    //                   authorName:
    //                     item.statusCode === "0"
    //                       ? "진행 중"
    //                       : item.statusCode === "1"
    //                       ? "완료"
    //                       : "실패",
    //                   datetime: moment(item.timestamp).format("HH:mm:ss"),
    //                   press: () => {
    //                     if (
    //                       item.statusCode === "1" ||
    //                       item.statusCode === "2"
    //                     ) {
    //                       const { history } = this.props;
    //                       history.push("/");
    //                     }
    //                   }
    //                 })
    //               )
    //             : newComponent(window.sap.m.StandardListItem, {
    //                 name: "noTransactionInfo",
    //                 altPrefix: "",
    //                 title: "진행한 작업이 없습니다."
    //               })
    //         }
    //       })
    //     },
    //     callback: (comp, isInitial) => {
    //       if (isInitial) {
    //         byId("app").addDependent(comp);
    //       }
    //       if (!comp.isOpen()) {
    //         comp.openBy(oEvent.getSource());
    //       }
    //     }
    //   });
    // };

    onPressSwitchSystem = () => {
      const { history } = this.props;

      history.push("/switchsystem");
    };

    refreshFromContainer = page => {
      if (!page) {
        return;
      }

      if (page.ignore) {
        return;
      }

      const { dispatch } = this.props;
      const currentState = store.getState();
      const { currentUser, showRootNotice } = currentState.user;
      const { RootNotice: rootNotice } =
        tryit(() => currentState["api-Configuration"].merged.ui.result) || {};

      const containerPage = byId("containerPage");

      if (!containerPage) {
        return;
      }

      containerPage.removeAllContent();

      containerPage.removeAllCustomData();
      containerPage.addCustomData(
        customData.set({
          key: "isFromFt",
          value: page.isFromFt
        })
      );

      document.title = page.title || "BSG Support";
      titleRef.current.textContent = page.title || "BSG Support";

      if (rootNotice && showRootNotice === true) {
        const valid = tryit(() => {
          const validator = new Function(...rootNotice.Validator);
          return validator();
        });
        if (valid === true) {
          containerPage.addContent(
            newComponent(window.sap.m.MessageStrip, {
              name: "containerMessageStripRootNotice",
              altPrefix: "",
              showCloseButton: true,
              showIcon: true,
              enableFormattedText: true,
              text: rootNotice.Text.replace(
                "{sid}",
                currentUser.systemID
              ).replace("{pid}", currentUser.partnerID),
              close: function() {
                dispatch(closeRootNotice());
              }
            })
          );
        }
      }

      const oFooter = newComponent(window.sap.m.Toolbar, {
        name: "footerToolbar",
        settings: {
          width: "auto",
          content: []
        }
      });
      oFooter.removeAllContent();
      oFooter.removeStyleClass("ft-show-above-react-footer");

      page.content && page.content.map(ctt => containerPage.addContent(ctt));

      if (page.customFooterContent) {
        oFooter.addStyleClass("ft-show-above-react-footer");
        containerPage.addContent(oFooter);
        page.customFooterContent.map(ctt => oFooter.addContent(ctt));
      }

      if (!currentUser.is_authenticated) {
      } else {
        // const { transactionList } = this.props;
        // header.addContent(
        //   newComponent(window.sap.m.Link, {
        //     name: "viewTransaction",
        //     altPrefix: "",
        //     settings: {
        //       tooltip: "현재 진행 중인 작업 건수입니다.",
        //       text: (() => {
        //         const ongoingLength = transactionList.filter(
        //           item => item.statusCode === "0"
        //         ).length;
        //         if (transactionList.length === 0) {
        //           return "";
        //         } else if (ongoingLength === 0) {
        //           return `${transactionList.length}개 작업 완료`;
        //         } else {
        //           return `작업 진행 중
        // (${ongoingLength}/${transactionList.length})`;
        //         }
        //       })(),
        //       press: this.openTransactionPopover(transactionList)
        //     },
        //     callback: (comp, isInitial) => {
        //       if (tryit(() =>
        // byId("transactionPopover").isOpen()) === true) {
        //         comp.firePress();
        //       }
        //     }
        //   })
        // );
        //               newComponent(window.sap.m.MenuItem, {
        //                 name: getNewName("menuItemSwitchSystem"),
        //                 altPrefix: "",
        //                 allowReset: false,
        //                 settings: {
        //                   customData: [
        //                     newComponent(window.sap.ui.core.CustomData, {
        //                       name:
        // getNewName("rightMenuItemSwitchLongText"),
        //                       altPrefix: "",
        //                       settings: {
        //                         key: "longText",
        //                         value: "다른 시스템으로 이동합니다"
        //                       }
        //                     })
        //                   ],
        //                   text: "Switch System",
        //                   press: this.onPressSwitchSystem
        //                 },
        //                 callback: comp => {
        //                   comp.setVisible(currentUser.idp === "google");
        //                 }
        //               })
      }

      page && page.onRenderFinished && page.onRenderFinished();
      extraArgs && extraArgs.onRenderFinished && extraArgs.onRenderFinished();
    };

    getProps = () => {
      return this.props;
    };

    convertController = (controller, state, props) => {
      return modules.convertController(controller, state, props, this.getProps);
    };

    getParams = () => {
      return new Promise((resolve, reject) => {
        const currentState = store.getState();
        const currentProps = this.props;
        const params = [currentState, currentProps];
        if (ui5Controller) {
          if (ui5Controller instanceof Promise) {
            ui5Controller.then(data => {
              params.push(
                this.convertController(data, currentState, currentProps)
              );
              params.push({
                getState: store.getState,
                getProps: this.getProps,
                pageKey: this.pageKey,
                onResize: this.onResize.bind(this)
              });
              resolve(params);
            });
          } else {
            params.push(
              this.convertController(ui5Controller, currentState, currentProps)
            );
            params.push({
              getState: store.getState,
              getProps: this.getProps,
              pageKey: this.pageKey,
              onResize: this.onResize.bind(this)
            });
            resolve(params);
          }
        } else {
          if (currentProps.fn) {
            params.push(
              this.convertController(
                currentProps.fn,
                currentState,
                currentProps
              )
            );
          } else {
            params.push({});
          }
          params.push({
            getState: store.getState,
            getProps: this.getProps,
            pageKey: this.pageKey,
            onResize: this.onResize.bind(this)
          });
          resolve(params);
        }
      });
    };

    loadImports = () => {
      return new Promise((resolve, reject) => {
        this.getParams().then(params => {
          if (!ui5Creator) {
            resolve({ creator: function() {}, controller: params });
          } else if (typeof ui5Creator === "function") {
            // this.setState({ creator: ui5Creator, controller: params });

            resolve({ creator: ui5Creator, controller: params });
          } else if (isObject(ui5Creator)) {
            // this.setState({ creator: ui5Creator, controller: params });

            resolve({
              creator: ui5Creator.createComponent,
              controller: params
            });
          } else if (ui5Creator instanceof Promise) {
            ui5Creator.then(ui5 => {
              const creator = ui5.createComponent || ui5.default;
              if (creator) {
                // this.setState({ creator: creator, controller: params });

                resolve({ creator: creator, controller: params });
              }
            });
          } else {
            /* import() function only accepts literal string as argument,
             * so I could't implement dynamic improt using this.pageKey.
             */
            // if (componentName) {
            //   import(`components/production_confirm/
            // ${componentName}.ui5`).then(
            //     ui5 => {
            //       if (ui5.default) {
            //         import(
            //           `components/production_confirm/
            // ${componentName}.controller`
            //         )
            //           .then(controller => {
            //             this.refreshFromContainer(
            //               ui5.default(
            //                 ...params
            //               )
            //             );
            //           })
            //           .catch(() => {
            //             this.refreshFromContainer(ui5.default(...params));
            //           });
            //       }
            //     }
            //   );
            // }
          }
        });
      });
    };

    handleCreator = data => {
      const promise = new Promise((resolve, reject) => {
        if (this.willUnmount === true) {
          return resolve();
        }
        // console.debug("handleCreator:", this.pageKey);
        const { creator, controller } = data;

        if (!window.randomPrefixObject) {
          window.randomPrefixObject = {};
        }

        if (!window.randomPrefixObject[this.pageKey]) {
          window.randomPrefixObject[this.pageKey] = [makeid(5, "a"), "_"].join(
            ""
          );
        }

        window.randomPrefixForEachPage =
          window.randomPrefixObject[this.pageKey];

        this.refreshFromContainer(creator(...controller));
        resolve();
      });
      this.prevPromise = promise;
      return promise;
    };

    toggleProperty(mediaName, oControl) {
      const fgIDs = oControl.getFieldGroupIds();
      /* [ 
        "toggleProperty", 
        "sap.ui.layout.form.Form:SetWidth:Phone:100%", 
        "sap.ui.layout.form.Form:SetWidth:else:50vw" 
      ] */
      const properties = fgIDs
        .reduce((acc, fgID) => {
          const splitted = fgID.split(":");
          if (splitted.length >= 4) {
            const [compClass, id, media, value, type] = splitted;
            let newValue = value;
            if (type === "boolean") {
              newValue = Boolean(parseInt(value, 10));
            } else if (value === "https") {
              if (type) {
                newValue = [value, type].join(":");
              }
            }
            return acc.concat({ id, media, value: newValue, class: compClass });
          }
          return acc;
        }, [])
        .reduce((acc, prop) => {
          if (prop.media === mediaName) {
            // 정확하게 mediaName과 일치한 항목이 있을 경우 이미 추가된 else는 삭제함.
            acc = acc.filter(
              each => each.id === prop.id && each.media !== "else"
            );
            acc.push(prop);
          } else if (prop.media === "else") {
            // 정확하게 mediaName과 일치한 항목이 있었는지 확인
            const exactMatchedIndex = acc.findIndex(
              each => each.id === prop.id && each.media === mediaName
            );
            if (exactMatchedIndex >= 0) {
              return acc;
            } else {
              acc.push(prop);
            }
          }

          return acc;
        }, []);
      // console.log("Properties:", mediaName, properties, oControl);
      properties.forEach(each => {
        if (oControl.getMetadata().getName() === each.class) {
          oControl[each.id](each.value);
        }
      });
    }

    toggleFixedLayout(mediaName, oControl) {
      if (oControl.getMetadata().getName() === "sap.m.Table") {
        // pass
      } else {
        return;
      }
      switch (mediaName) {
        case "Phone":
          oControl.setFixedLayout(true);
          break;
        default:
          oControl.setFixedLayout(false);
          break;
      }
    }

    onResize(mParams, oControl) {
      if (oControl) {
        const fgIDs = oControl.getFieldGroupIds();
        if (fgIDs.includes("toggleProperty")) {
          this.toggleProperty(mParams.name, oControl);
        } else {
          this.toggleFixedLayout(mParams.name, oControl);
        }
      } else {
        const tflFunc = this.toggleFixedLayout;
        const tpFunc = this.toggleProperty;

        window.sap.ui.require(["sap/ui/core/Core"], function(core) {
          const tables = core
            .byFieldGroupId("toggleFixedLayout")
            .filter(oComp => oComp.getMetadata().getName() === "sap.m.Table");
          tables.forEach(oComp => {
            tflFunc(mParams.name, oComp);
          });

          const oToggleProperty = core.byFieldGroupId("toggleProperty");
          oToggleProperty.forEach(oComp => {
            tpFunc(mParams.name, oComp);
          });
        });
      }
    }
    componentDidMount() {
      // console.debug("render5 - componentDidMount:", this.pageKey);
      const content = document.getElementById("content");
      if (content.style.visibility === "hidden") {
        content.style.visibility = "visible";
      }
      window.sap.ui.Device.media.attachHandler(this.onResize, this);

      this.loadImports().then(data => {
        this.handleCreator(data);
      });
    }

    reduce(obj) {
      return Object.keys(obj).reduce((result, key) => {
        if (key !== "children") {
          result[key] = obj[key];
        }

        return result;
      }, {});
    }

    componentDidUpdate(prevProps, prevState) {
      // console.debug("render5 - componentDidUpdate:", this.pageKey);
      if (this.prevPromise) {
        this.prevPromise.then(() => {
          this.loadImports().then(data => {
            this.handleCreator(data);
          });
        });
      } else {
        this.loadImports().then(data => {
          this.handleCreator(data);
        });
      }
    }

    componentWillUnmount() {
      // console.debug("render5 - componentWillUnmount:", this.pageKey);
      window.sap.ui.Device.media.detachHandler(this.onResize, this);

      this.willUnmount = true;

      const containerPage = byId("containerPage");

      if (!containerPage) {
        return;
      }

      containerPage.removeAllContent();

      containerPage.removeAllCustomData();

      const content = document.getElementById("content");
      content.style.visibility = "hidden";
    }

    render() {
      // console.debug("render5 - render");

      const { forwardedRef, ...rest } = this.props;
      return <WrappedComponent {...rest} ref={forwardedRef} />;

      // return <WrappedComponent {...this.props} />;
    }
  }

  function mapStateToProps(state, ownProps) {
    return {
      // transactionList: state.transaction.list
    };
  }
  return connect(mapStateToProps, null, null, { forwardRef: true })(
    React.forwardRef((props, ref) => {
      return <Render5 {...props} forwardedRef={ref} />;
    })
  );
};

export default hoc;
