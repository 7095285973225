import produce from "immer";

import {
  ADD_ERROR,
  REMOVE_ERROR,
  ADD_NOTICE,
  REMOVE_CURRENT_NOTICE,
  CLEAR_NOTICE,
  OPEN_MESSAGE_BOX,
  CLOSE_MESSAGE_BOX
} from "actions/ui5";

const initialState = {
  errorList: [],
  noticeList: [],
  ignoreNotice: false,
  messageBox: {}
};

export default function ui5(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case ADD_ERROR:
        const newList =
          action.list instanceof Array ? action.list : [action.list];
        const newErrorList = newList.map(item => {
          const msg = typeof item === "string" ? { message: item } : item;
          const maxDesc = 350;

          if (typeof msg.type === "undefined" || msg.type === "error") {
            msg.type = window.sap
              ? window.sap.ui.core.MessageType.Error
              : "Error";
          }
          switch (msg.type) {
            case "S":
              msg.type = window.sap
                ? window.sap.ui.core.MessageType.Success
                : "Success";
              break;
            case "E":
              msg.type = window.sap
                ? window.sap.ui.core.MessageType.Error
                : "Error";
              break;
            case "W":
              msg.type = window.sap
                ? window.sap.ui.core.MessageType.Warning
                : "Warning";
              break;
            case "I":
            default:
              msg.type = window.sap
                ? window.sap.ui.core.MessageType.Information
                : "Information";
              break;
          }

          if (!msg.description) {
            msg.description = "No additional description";
          } else {
            if (msg.description > maxDesc && !msg.is_markup) {
              msg.description = `${msg.description.slice(0, maxDesc)}...`;
            }
          }
          return msg;
        });
        draft.errorList = [
          ...newErrorList,
          ...draft.errorList.filter(item => !item.willBeDelete)
        ];
        break;

      // case CLEAR_WS_PROCESS:
      //   draft.errorList = state.errorList
      //     .filter(event => !event.willBeDelete)
      //     .map(event => {
      //       event.willBeDelete = true;
      //       return event;
      //     });
      //   break;

      case REMOVE_ERROR:
        draft.errorList = initialState.errorList;
        break;
      case ADD_NOTICE: {
        draft.noticeList = draft.noticeList.concat(action.list);
        draft.ignoreNotice = action.ignoreIndicator;
        break;
      }
      case REMOVE_CURRENT_NOTICE: {
        draft.noticeList = draft.noticeList.slice(1);
        break;
      }
      case CLEAR_NOTICE: {
        draft.noticeList = initialState.noticeList;
        break;
      }
      case OPEN_MESSAGE_BOX: {
        const { text, yes, no, params } = action.payload;
        draft.messageBox = {
          text,
          yes,
          no,
          params
        };
        break;
      }
      case CLOSE_MESSAGE_BOX: {
        draft.messageBox = {};
        break;
      }
      default:
        break;
    }
  });
}
