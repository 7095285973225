import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "@reduxjs/toolkit";
import { connect } from "react-redux";
import Config from "@bsgp/lib-config";
import { tryit } from "@bsgp/lib-core";
import render5 from "hoc/render5";
import { getPathsForCurrentUser } from "actions/adir";

function MainPage(props) {
  const { dispatch, currentUser, history } = props;

  useEffect(() => {
    if (!currentUser.paths) {
      dispatch(getPathsForCurrentUser(currentUser));
    }

    const defaultPath = tryit(
      () => Config.ref.merged[Config.id.UI].DefaultEntry.Path
    );
    // console.log("location:", location);
    // if(location)
    defaultPath && history.replace(defaultPath);
  }, []);

  console.log("render main page");
  return true;
}

const onPressTile = (state, props) => oEvent => {
  const { history } = props;

  const customData_urlPath = oEvent
    .getSource()
    .getCustomData()
    .reduce((result, cd) => {
      if (cd.getKey() === "urlPath") {
        result = cd.getValue();
      }
      return result;
    }, null);

  if (customData_urlPath) {
    console.log(customData_urlPath);
    history.push(customData_urlPath);
  }
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.currentUser,
    currentPID: state.user.pid
  };
}

export default compose(
  connect(mapStateToProps),
  withRouter,
  render5(import("components/main.ui5"), {
    onPressTile
  })
)(MainPage);
