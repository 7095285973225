import { newComponent } from "ui5-lib-rc";

export function initUI5(props) {
  const containerPage = newComponent({
    targetClass: window.sap.m.ScrollContainer,
    name: "containerPage",
    altPrefix: "",
    settings: {
      // enableScrolling: false,
      backgroundDesign: window.sap.m.PageBackgroundDesign.Transparent,
      height: "100%",
      horizontal: false,
      vertical: true,
      content: []
    }
  });

  // const app =
  newComponent({
    targetClass: window.sap.m.App,
    name: "app",
    altPrefix: "",
    settings: {
      // showNavButton: false,
      // backgroundColor: "#fff",
      backgroundColor: "rgba(255, 255, 255, 0)"
      // pages: [containerPage]
    }
  });

  containerPage.placeAt("content");
}
