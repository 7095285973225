import { newProcess } from "ws-process";
import { makeid } from "@bsgp/lib-core";
import copy from "clipboard-copy";
import {
  signIn,
  asyncSignOut,
  setRequireMfa,
  setAlterSystems,
  selectLogInSystem,
  updateCookie
} from "actions/user";
import { tryit } from "ui5-lib-rc";
import { directoryV2, apiHub } from "@bsgp/lib-api";
import { storePathsForCurrentUser } from "actions/user";

export const generatePassword = () => {
  const newPassword = makeid(10);
  copy(newPassword).then(
    () => {
      alert("New Password is copied");
    },
    err => {
      alert(err);
    }
  );
  return newPassword;
};

export const DOING_VALIDATION = "DOING_VALIDATION";
export function doingValidation() {
  return {
    type: "DOING_VALIDATION"
  };
}

export const DONE_VALIDATION = "DONE_VALIDATION";
export function doneValidation({ hasError, error }) {
  return {
    type: "DONE_VALIDATION",
    hasError,
    error
  };
}

export const UPDATE_DATA = "UPDATE_DATA";
export function updateData(key, data, prevData) {
  return {
    type: "UPDATE_DATA",
    key,
    data,
    prevData
  };
}

export const REMOVE_DATA = "REMOVE_DATA";
export function removeData(key, id, partnerID, systemID) {
  return {
    type: "REMOVE_DATA",
    key,
    id,
    partnerID,
    systemID
  };
}

export const STORE_DATA = "STORE_DATA";
export function storeData(key, list, overwrite) {
  return {
    type: "STORE_DATA",
    key,
    list,
    overwrite
  };
}

export const RESTORE_DATA = "RESTORE_DATA";
export function restoreData(key) {
  return {
    type: "RESTORE_DATA",
    key
  };
}

export const naviToCreate = ({ history, key }) => () => {
  const orgPath = history.location.pathname;
  const regKey = new RegExp("/" + key + "s$");
  const newPath = [
    orgPath.replace(/\/{0,1}$/, "").replace(regKey, ""),
    `/${key}/create`
  ].join("");
  history.push(newPath);
};

export const goBack = ({ history, match, id }, type) => () => {
  console.log("match:", match);
  const regCreate = /\/create\/{0,1}.{0,}$/;
  const regEdit = /\/edit\/{0,1}.{0,}$/;
  const matchPath = match.path;
  const orgPath = history.location.pathname;
  const newPath = (function() {
    if (regCreate.test(matchPath)) {
      if (type === 4) {
        return orgPath.replace(regCreate, "");
      }
      if (id) {
        return orgPath.replace(regCreate, "/" + id);
      } else {
        return orgPath.replace(regCreate, "").replace(/\/[^/]+$/, "");
      }
    } else if (regEdit.test(matchPath)) {
      if (id) {
        return orgPath.replace(regEdit, "").replace(/\/[^/]+$/, "/" + id);
      } else {
        return orgPath.replace(regEdit, "");
      }
    } else {
      const regID = tryit(() => {
        switch (type) {
          case 2:
            return /\/:id\/{1}[^/]{0,}$/;
          case 3:
            return "";
          case 4:
            return /\/:id(\/{1}[^/]{0,}|\/{0}$)/;
          case 5:
            return /\/[a-z]{1,}\/:[a-z]{1,}ID\/{0,1}$/;
          default:
            return /\/[^/]{1,}\/:id(\/{1}[^/]{0,}|\/{0}$)/;
        }
      });

      return Object.keys(match.params).reduce((result, key) => {
        return result.replace(`:${key}`, match.params[key]);
      }, matchPath.replace(regID, ""));
    }
  })();
  history.replace(newPath);
};

export const asyncUpdateData = (params, callback) => () => {
  return newProcess("updateData")
    .newRequest({
      requestHandler: reqUpdateData,
      params,
      responseHandler: resUpdateData,
      callback
    })
    .start();
};

const reqUpdateData = ({ params }) => () => {
  return {
    version: "20190625",
    action: "manageadir",
    subAction: "updateData",
    params
  };
};

const resUpdateData = ({ jsonResponse }) => () => {
  const body = jsonResponse.body;
  console.log("body", body);
  return [true, body];
};

export const asyncSignInWithGoogle = (data, callback) => {
  return dispatch => {
    const user = { idp: "google" };
    user.id_token = data.id_token;
    user.partnerID = data.partnerID;
    user.systemID = data.systemID;

    return dispatch(asyncSignIn(user, callback));
  };
};

const loginUrl = [
  "https://",
  [
    "api",
    ["dev", "staging"].find(
      each => each === process.env.REACT_APP_API_STAGE_NAME
    )
  ]
    .filter(Boolean)
    .join("-"),
  ".bsg.support/login"
].join("");

function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let idx = 0; idx < ca.length; idx++) {
    let char = ca[idx];
    while (char.charAt(0) === " ") {
      char = char.substring(1);
    }
    if (char.indexOf(name) === 0) {
      return char.substring(name.length, char.length);
    }
  }
  return "";
}

export const asyncSignIn = (params, callback) => {
  return (dispatch, getState) => {
    const promise = apiHub.post(loginUrl, {
      Idp: params.idp,
      IdToken: params.id_token,
      Username: params.username,
      Password: params.password,
      PartnerId: params.partnerID,
      SystemId: params.systemID
    });
    promise
      .then(data => {
        const [isValid] = dispatch(resSignIn(data.data));
        if (isValid === null) {
          const errMsg = [
            "Request is determined to be failed",
            "and the process is terminated as your wish"
          ].join(", ");
          const err = new Error(errMsg);
          throw err;
        }
        return data;
      })
      .catch(ex => {
        if (ex.errorOccurred === true) {
          if (
            ["SIGN_IN/NO_USER", "SIGN_IN/INVALID_PASSWORD"].includes(
              ex.data.body.errorCode
            )
          ) {
            // pass
          } else {
            throw ex;
          }
        }
      })
      .then(data => {
        if (data === undefined) {
          return;
        }
        const { getState } = data;

        const { currentUser, idpRedirected } = getState().user;
        const { username, password, systemID, partnerID } = params;

        if (currentUser.system.useSso) {
          if (username && password && systemID && partnerID) {
            const stage = process.env.REACT_APP_API_STAGE_NAME;
            const formAction = `https://idp.bsg.support/${stage}/sso/post`;
            const ssoForm = document.createElement("form");
            ssoForm.method = "POST";
            ssoForm.action = formAction;
            ssoForm.target = idpRedirected ? "_self" : "_blank";
            [
              { name: "action", value: formAction },
              { name: "username", value: username },
              { name: "password", value: password },
              { name: "idp_session", value: currentUser.session },
              { name: "open_as_new_tab", value: !idpRedirected },
              { name: "systemID", value: systemID },
              { name: "partnerID", value: partnerID }
            ].forEach(info => {
              const elInput = document.createElement("input");
              elInput.type = "hidden";
              elInput.name = info.name;
              elInput.value = info.value;
              ssoForm.appendChild(elInput);
            });

            document.body.appendChild(ssoForm);
            ssoForm.submit();
            document.body.removeChild(ssoForm);
          }
          return data;
        }

        const longterm_path = currentUser.system.forward2LongTerm;
        const oldLongtermPath = getCookie("longterm_path");
        console.log("longterm_path", longterm_path, oldLongtermPath);

        if (longterm_path) {
          if (oldLongtermPath === longterm_path) {
            console.log("same longterm_path", longterm_path);
            const displayedVersion = process.env.REACT_APP_RELEASE_VERSION;
            let diffWithDisplaying = false;
            if (displayedVersion) {
              if (!displayedVersion.startsWith(longterm_path)) {
                diffWithDisplaying = true;
              }
            }
            console.log("diffWithDisplaying:", diffWithDisplaying);
            updateCookie("longterm_path", longterm_path, 7, diffWithDisplaying);
          } else {
            console.log("different longterm_path", longterm_path);
            updateCookie("longterm_path", longterm_path, 7, true);
          }
        } else {
          if (oldLongtermPath === "") {
            console.log("same longterm_path", longterm_path);
            updateCookie("longterm_path", "", -1, false);
          } else {
            console.log("different longterm_path", longterm_path);
            updateCookie("longterm_path", "", -1, true);
          }
        }

        return data;
      });
    return promise;
  };
};

const resSignIn = ({ jsonResponse }) => (dispatch, getState) => {
  const { user, requireMfa, chooseSystem, id_token } = jsonResponse.body;

  if (chooseSystem === true) {
    const { currentUser } = getState().user;
    if (user.systems.length > 0) {
      if (
        (!currentUser.systemID && !currentUser.partnerID) ||
        user.systems.filter(
          sys =>
            sys.systemID === currentUser.systemID &&
            sys.partnerID === currentUser.partnerID
        ).length === 0
      ) {
        dispatch(
          selectLogInSystem(
            [user.systems[0].systemID, user.systems[0].partnerID].join("@")
          )
        );
      }
    }
    dispatch(
      setAlterSystems({ systems: user.systems, idp: user.idp, id_token })
    );
    return [null];
  } else if (requireMfa === true) {
    dispatch(setRequireMfa());
    return [null];
  } else {
    dispatch(signIn(user));
    return [true, user];
  }
};

export const asyncGetDataByTag = params => () => {
  return newProcess("queryDirectoryDataByTag")
    .newRequest({
      requestHandler: reqGetData,
      params: { queryByTag: params }
    })
    .start();
};

export const asyncGetDataV2 = params => () => {
  return newProcess("queryDirectoryData")
    .newRequest({
      requestHandler: reqGetData,
      params: { query: params }
    })
    .start();
};

export const asyncGetData = (params, callback) => {
  return () => {
    const promise = newProcess("getData")
      .newRequest({
        requestHandler: reqGetData,
        params: { query: params },
        responseHandler: resGetData,
        callback
      })
      .start();
    promise.then(data => {
      // console.log("dta", data);
    });
  };
};

const reqGetData = ({ params }) => () => {
  return {
    version: "20190625",
    action: "manageadir",
    subAction: "getData",
    params
  };
};

const resGetData = ({ jsonResponse }) => () => {
  const body = jsonResponse.body;
  const count = jsonResponse.count;
  return [true, body, { count }];
};

export const getPathsForCurrentUser = currentUser => dispatch => {
  if (currentUser.partnerID === "36pc5h4ur0") {
    return directoryV2.rest.user.queryMenus(
      {
        uid: currentUser.id,
        pid: currentUser.partnerID,
        sid: currentUser.systemID,
        includeRoles: true
      },
      {
        afterSucceed: data => {
          // console.log("data:", data);
          dispatch(
            storePathsForCurrentUser(
              data.list,
              data.roles,
              data.subPathCollection
            )
          );
        },
        afterFailed: () => {
          dispatch(storePathsForCurrentUser());
        }
      }
    );
  }
  return newProcess("fetchRole")
    .newRequest({
      requestHandler: ({ params }) => () => {
        return {
          version: "20190625",
          action: "manageadir",
          subAction: "getUser",
          expiresIn: [1, "months"],
          params
        };
      },
      params: {
        queryPathsForUser: {
          userID: currentUser.id,
          partnerID: currentUser.partnerID,
          systemID: currentUser.systemID,
          includeRoles: true
        }
      }
    })
    .start()
    .then(({ data }) => {
      const body = tryit(() => {
        return data.jsonResponse.body;
      });
      const error = tryit(
        () => {
          return body[0].errorMessage;
        },
        tryit(() => {
          return body.errorMessage;
        })
      );

      return new Promise((resolve, reject) => {
        if (error) {
          reject(error);
        } else {
          const result = tryit(() => {
            return body;
          });
          resolve(result);
        }
      });
    })
    .catch(err => {
      if (err.data) {
        const invalidAccess = tryit(() => err.data.body["invalid-access"]);
        if (invalidAccess) {
          dispatch(asyncSignOut());
        }
      }

      return new Promise((resolve, reject) => {
        reject(err);
      });
    })
    .then(({ paths, roles, subPathCollection }) => {
      dispatch(storePathsForCurrentUser(paths, roles, subPathCollection));
    })
    .catch(err => {
      dispatch(storePathsForCurrentUser());
    });
};
