(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@bsgp/lib-core"), require("moment"));
	else if(typeof define === 'function' && define.amd)
		define(["@bsgp/lib-core", "moment"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@bsgp/lib-core"), require("moment")) : factory(root["@bsgp/lib-core"], root["moment"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__228__, __WEBPACK_EXTERNAL_MODULE__416__) => {
return 