import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { Toast } from "@ui5/webcomponents-react";

function MessageToast(props) {
  const { errorList } = props;

  const toastRef = useRef(null);

  useEffect(() => {
    if (errorList.length > 0) {
      toastRef.current.show();
    }
  }, [errorList.length]);

  return (
    <>
      {errorList.length > 0 && (
        <Toast ref={toastRef} duration={5000}>
          {errorList[0].message}
        </Toast>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    errorList: state.ui5.errorList
  };
}

export default connect(mapStateToProps)(MessageToast);
