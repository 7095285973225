import moment from "moment";

import { defined } from "@bsgp/lib-core";
import { isString, isFalsy, isObject, isNumber } from "@bsgp/lib-core";

const defaultInvalidDate = "";

const locale = window.navigator.userLanguage || window.navigator.language;

const koLocale = {
  months: "1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월".split("_"),
  monthsShort: "1월_2월_3월_4월_5월_6월_7월_8월_9월_10월_11월_12월".split("_"),
  weekdays: "일요일_월요일_화요일_수요일_목요일_금요일_토요일".split("_"),
  weekdaysShort: "일_월_화_수_목_금_토".split("_"),
  weekdaysMin: "일_월_화_수_목_금_토".split("_"),
  longDateFormat: {
    LT: "A h:mm",
    LTS: "A h:mm:ss",
    L: "YYYY.MM.DD.",
    LL: "YYYY년 MMMM D일",
    LLL: "YYYY년 MMMM D일 A h:mm",
    LLLL: "YYYY년 MMMM D일 dddd A h:mm",
    l: "YYYY.MM.DD.",
    ll: "YYYY년 MMMM D일",
    lll: "YYYY년 MMMM D일 A h:mm",
    llll: "YYYY년 MMMM D일 dddd A h:mm"
  },
  calendar: {
    sameDay: "오늘 LT",
    nextDay: "내일 LT",
    nextWeek: "dddd LT",
    lastDay: "어제 LT",
    lastWeek: "지난주 dddd LT",
    sameElse: "L"
  },
  relativeTime: {
    future: "%s 후",
    past: "%s 전",
    s: "몇 초",
    ss: "%d초",
    m: "1분",
    mm: "%d분",
    h: "한 시간",
    hh: "%d시간",
    d: "하루",
    dd: "%d일",
    M: "한 달",
    MM: "%d달",
    y: "일 년",
    yy: "%d년"
  },
  dayOfMonthOrdinalParse: /\d{1,2}(일|월|주)/,
  ordinal: function(number, period) {
    switch (period) {
      case "d":
      case "D":
      case "DDD":
        return number + "일";
      case "M":
        return number + "월";
      case "w":
      case "W":
        return number + "주";
      default:
        return number;
    }
  },
  meridiemParse: /오전|오후/,
  isPM: function(token) {
    return token === "오후";
  },
  meridiem: function(hour, minute, isUpper) {
    return hour < 12 ? "오전" : "오후";
  }
};

moment.locale(locale, {
  invalidDate: defaultInvalidDate,
  ...(locale === "ko" ? koLocale : {})
});
moment.updateLocale(locale, { invalidDate: defaultInvalidDate });
if (process.env.NODE_ENV === "production") {
  console.log("locale:", locale, "moment.locale():", moment.locale());
}

// import updateLocale from "dayjs/plugin/updateLocale";
// import utc from "dayjs/plugin/utc";

// moment.extend(updateLocale);
// moment.extend(utc);

const defaultTimeZone = "+09:00";
const defaultFormat = {
  YEAR: "YYYY",
  MONTH: "MM",
  DATE: "DD",
  HOUR: "HH",
  MINUTE: "mm",
  SECOND: "ss"
};
const gFormat = {
  ...defaultFormat
};
gFormat.ui5 = {
  ...defaultFormat,
  YEAR: "yyyy",
  DATE: "dd"
};
gFormat.ui5.date = [gFormat.ui5.YEAR, gFormat.ui5.MONTH, gFormat.ui5.DATE].join(
  "-"
);
gFormat.ui5.time = [
  gFormat.ui5.HOUR,
  gFormat.ui5.MINUTE,
  gFormat.ui5.SECOND
].join(":");
gFormat.ui5.datetime = [gFormat.ui5.date, gFormat.ui5.time].join(" ");

gFormat.A1Date = [gFormat.YEAR, gFormat.MONTH, gFormat.DATE].join("");
gFormat.A1Time = [gFormat.HOUR, gFormat.MINUTE, gFormat.SECOND].join("");
gFormat.default = {
  date: [gFormat.YEAR, gFormat.MONTH, gFormat.DATE].join("-"),
  time: [gFormat.HOUR, gFormat.MINUTE, gFormat.SECOND].join(":")
};
gFormat.default.datetime = [gFormat.default.date, gFormat.default.time].join(
  " "
);
gFormat.a1 = {
  date: gFormat.A1Date,
  time: gFormat.A1Time
};

gFormat.date = { ui5: {}, js: {} };
gFormat.date.ui5.dash = gFormat.ui5.date;
gFormat.date.ui5.none = [
  gFormat.ui5.YEAR,
  gFormat.ui5.MONTH,
  gFormat.ui5.DATE
].join("");
gFormat.date.ui5.dot = [
  gFormat.ui5.YEAR,
  gFormat.ui5.MONTH,
  gFormat.ui5.DATE
].join(".");

gFormat.date.js.dash = [gFormat.YEAR, gFormat.MONTH, gFormat.DATE].join("-");
gFormat.date.js.none = [gFormat.YEAR, gFormat.MONTH, gFormat.DATE].join("");
gFormat.date.js.dot = [gFormat.YEAR, gFormat.MONTH, gFormat.DATE].join(".");

gFormat.time = { ui5: {}, js: {} };
gFormat.time.ui5.colon = gFormat.ui5.time;
gFormat.time.ui5.none = [
  gFormat.ui5.HOUR,
  gFormat.ui5.MINUTE,
  gFormat.ui5.SECOND
].join("");
gFormat.date.js.colon = [gFormat.HOUR, gFormat.MINUTE, gFormat.SECOND].join(
  ":"
);
gFormat.date.js.none = [gFormat.HOUR, gFormat.MINUTE, gFormat.SECOND].join("");

function getString(dateObj, presetTime, option = {}) {
  if (isObject(presetTime)) {
    option = presetTime;
  }
  const _presetTime = isString(presetTime) ? presetTime : option.presetTime;
  const tz = defined(option.tz, defaultTimeZone);
  const outputAsISO = defined(option.outputAsISO, false);
  const toUTC = defined(option.toUTC, false);
  const format = option.format;

  // const zoneParsed = moment.parseZone(
  //   `${dateString}${tz}`,
  //   `${gFormat.default.date}T${gFormat.default.time}${
  //     outputAsISO === true ? ".SSS" : ""
  //   } ZZ`
  // );

  const zoneParsed = moment(dateObj).utcOffset(tz);
  if (["b", "begin"].findIndex(itm => itm === _presetTime) >= 0) {
    zoneParsed.startOf("date");
  } else if (["e", "end"].findIndex(itm => itm === _presetTime) >= 0) {
    zoneParsed.endOf("date");
  }

  const dateUTC = toUTC === true ? zoneParsed.utc() : zoneParsed;

  if (outputAsISO === true) {
    return dateUTC.format(
      defined(format, `${gFormat.default.date}T${gFormat.default.time}.SSS[Z]`)
    );
  } else {
    return dateUTC.format(
      defined(format, `${gFormat.default.date}T${gFormat.default.time}[Z]`)
    );
  }
}

/**
 * @typedef {Object} instance Datetime object
 * @property {Date} date Date type in javascript
 * @property {string} timezone ex. "+09:00"
 * @property {string} format ex. "YYYY-MM-DD"
 * @property {string} formatted A string datetime based on format
 * @property {string} utcString
 * @property {function} changeFormat
 * Changes format and formatted of self, and returns new formatted
 */

/**
 * Create an instance for Datetime
 * @example
 * import Datetime from "@bsgp/lib-date";
 * Datetime.create(new Date());
 * Datetime.create("/Date(1541376000000)/");
 * Datetime.create(/* Any valid data can be accepted by momentjs *\/);
 *
 * @param {(Date|string)} inDate - - It may be
 *  - normal Date type in javascript
 *  - string formatted like "/Date(1541376000000)/"
 *  - any valid data that is supported by momentjs
 * @param {Object} [options]
 * @param {string} [options.timezone] ex. "+09:00"
 * @param {string} [options.format]
 * ex. "YYYY-MM-DD HH:mm:ss"
 * @returns {instance}
 */
function create(inDate, options = {}) {
  // sapDate = "/Date(1541376000000)/"
  const [date, timezone, format] = ((sapDate = inDate) => {
    const timezone = defined(options.timezone, defaultTimeZone);
    const format = defined(options.format, gFormat.default.datetime);

    if (sapDate instanceof Date) {
      return [sapDate, timezone, format];
    }
    if (isFalsy(sapDate)) {
      return [defaultInvalidDate, timezone, format];
    }
    if (isString(sapDate)) {
      if (/^\d{1,}$/.test(sapDate)) {
        if (options.format) {
          return [moment(sapDate, format).toDate(), timezone, format];
        }
        return [moment(parseInt(sapDate, 10)).toDate(), timezone, format];
      }

      const numberString = sapDate.replace(/^\/Date\(/, "").replace(")/", "");
      if (/^\d{1,}$/.test(numberString)) {
        return [new Date(parseInt(numberString, 10)), timezone, format];
      }

      if (options.format === undefined) {
        return [moment(sapDate).toDate(), timezone, format];
      }
    }
    if (isNumber(sapDate)) {
      return [moment(sapDate).toDate(), timezone, format];
    }
    return [moment(sapDate, format).toDate(), timezone, format];
  })();

  const now = moment();

  const result = {
    date,
    timezone,
    fromNow: moment(date).from(now),
    format,
    utcOffset: now.utcOffset(),
    changeFormat: function($format, options = {}) {
      const toUTC = defined(options.toUTC, false);
      const { presetTime } = options;

      this.format = $format;
      this.formatted = getString(this.date, {
        format: this.format,
        tz: this.timezone,
        toUTC,
        presetTime
      });
      return this.formatted;
    },
    getISOString: function(option = {}) {
      const begin = defined(option.begin, false);
      const end = defined(option.end, false);
      const toUTC = defined(option.toUTC, false);

      if (begin === true) {
        return getString(this.date, "begin", {
          tz: this.timezone,
          outputAsISO: true,
          toUTC
        });
      } else if (end === true) {
        return getString(this.date, "end", {
          tz: this.timezone,
          outputAsISO: true,
          toUTC
        });
      }
      return getString(this.date, {
        tz: this.timezone,
        outputAsISO: true,
        toUTC
      });
    },
    formatted: moment(date)
      .utcOffset(timezone)
      .format(format),
    utcString: getString(date, { tz: timezone, toUTC: true })
  };
  return result;
}

/**
 * Add specific working days to Datetime
 *
 * @example
 * import { addWorkingDay } from "@bsgp/lib-date";
 * const datetime = addWorkingDay(instance, 10)
 *
 * @example
 * import Datetime from "@bsgp/lib-date";
 * const datetime = Datetime.addWorkingDay(instance, 10)
 *
 * @param {instance} date instance of Datetime
 * @param {integer} days
 * @returns {instance}
 */
function addWorkingDay(date, days) {
  const mDate = moment(date.date).utcOffset(date.timezone);
  const newDays = days + Math.floor((mDate.day() + days - 1) / 5) * 2;

  if (days >= 5) {
  }
  mDate.add(newDays, "days");
  return create(mDate.toDate(), { timezone: date.timezone });
}

/**
 * Add or subtract days from date
 * @param {Date} date
 * @param {Number} number
 * @param {Object} [option]
 * @param {Boolean} [option.wantWorkDay] - - See below
 * - If true, when result date is Sunday or Saturday, it returns Monday
 * @returns {instance} - DateTime object
 */
function add(date, number, pOption, spare) {
  let option = {};
  if (isString(pOption)) {
    option = spare || {};
    option.unit = pOption;
  } else {
    option = pOption || {};
  }
  const wantWorkDay = defined(option.wantWorkDay, false);
  const unit = defined(option.unit, "days");
  const mDate = moment(date);

  mDate.add(number, unit);
  if (wantWorkDay === true) {
    if (mDate.day() === 0) {
      // It is Sunday
      mDate.add(1, unit);
    } else if (mDate.day() === 6) {
      // It is Saturday
      mDate.add(2, unit);
    }
  }
  return create(mDate.toDate(), { timezone: mDate.utcOffset() });
}

/**
 * the duration of a difference between date from, date to
 * @param {(Date|string)} [fromDate] - - It may be
 *  - normal Date type in javascript
 *  - string formatted like "/Date(1541376000000)/"
 *  - any valid data that is supported by momentjs
 * @param {string} [type] - - See below
 * - type of date distance
 * - string: years, months, weeks, days, hours, minutes, and seconds.
 *  - any valid value that is supported by momentjs: difference
 * @param {(Date|string)} [option.to] - - same Type as @param fromDate
 *  - if firstDayOf exist, fn get distance between fromDate & firstDayOf value
 * @param {string} [option.firstDayOf] - - See below
 *  - string: year, month, week, day, date, etc.
 *  - any valid value that is supported by momentjs: Start of Time
 * @returns {Number} - number
 */
function distance(fromDate, type, options = {}) {
  const { firstDayOf, to } = options;
  const disType = moment.normalizeUnits(type);
  const firstDayOfTo = moment.normalizeUnits(firstDayOf);

  const fDate = moment(fromDate);

  fDate.startOf(disType);

  const tDate = to ? moment(to) : moment();

  if (firstDayOfTo) {
    tDate.startOf(firstDayOfTo);
    const tDateCloned = tDate.clone().startOf(disType);
    if (tDateCloned.isBefore(tDate)) {
      tDate.startOf(disType);
    }
  } else {
    tDate.startOf(disType);
  }

  const result = fDate.diff(tDate, type, true);

  if (Number.isNaN(result)) {
    return result;
  }

  return Math.abs(result) + 1;
}

create.format = gFormat;
const addDays = add;
const Datetime = create;
const DateTime = create;

export {
  addWorkingDay,
  add,
  addDays,
  create,
  DateTime,
  Datetime,
  getString,
  moment,
  gFormat as format
};

const defaultExports = {
  Datetime,
  DateTime,
  create,
  addWorkingDay,
  add,
  addDays,
  distance,
  getString,
  moment,
  format: gFormat
};
export default defaultExports;
