export const ADD_TRANSACTION = "ADD_TRANSACTION";
export const addTransaction = (id, text, time) => {
  return {
    type: ADD_TRANSACTION,
    id,
    text,
    time
  };
};

export const REMOVE_TRANSACTION = "REMOVE_TRANSACTION";
export const removeTransaction = (id, success, time) => {
  return {
    type: REMOVE_TRANSACTION,
    id,
    success,
    time
  };
};

export const CLEAR_TRANSACTION = "CLEAR_TRANSACTION";
export const clearTransaction = () => {
  return {
    type: CLEAR_TRANSACTION
  };
};
