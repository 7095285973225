(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@bsgp/lib-api/store"), require("@bsgp/lib-core"), require("actions/user"), require("react"), require("react-redux"), require("title"), require("ui5-lib-rc"));
	else if(typeof define === 'function' && define.amd)
		define(["@bsgp/lib-api/store", "@bsgp/lib-core", "actions/user", "react", "react-redux", "title", "ui5-lib-rc"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("@bsgp/lib-api/store"), require("@bsgp/lib-core"), require("actions/user"), require("react"), require("react-redux"), require("title"), require("ui5-lib-rc")) : factory(root["@bsgp/lib-api/store"], root["@bsgp/lib-core"], root["actions/user"], root["react"], root["react-redux"], root["title"], root["ui5-lib-rc"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, (__WEBPACK_EXTERNAL_MODULE__171__, __WEBPACK_EXTERNAL_MODULE__228__, __WEBPACK_EXTERNAL_MODULE__883__, __WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__148__, __WEBPACK_EXTERNAL_MODULE__434__, __WEBPACK_EXTERNAL_MODULE__877__) => {
return 