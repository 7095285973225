import produce from "immer";
import {
  ADD_TRANSACTION,
  REMOVE_TRANSACTION,
  CLEAR_TRANSACTION
} from "actions/transaction";

const initialState = {
  list: []
};

export default function transaction(state = initialState, action) {
  return produce(state, draft => {
    switch (action.type) {
      case ADD_TRANSACTION:
        draft.list.filter(item => item.id !== action.id);
        draft.list.unshift({
          id: action.id,
          text: action.text,
          statusCode: "0",
          timestamp: action.time
        });
        break;
      case REMOVE_TRANSACTION:
        draft.list.forEach(item => {
          if (item.id === action.id) {
            item.statusCode = action.success ? "1" : "2";
            item.timestamp = action.time;
          }
        });
        break;
      case CLEAR_TRANSACTION:
        draft.list = draft.list.filter(item => item.statusCode === "0");
        break;
      default:
        break;
    }
  });
}
