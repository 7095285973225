import React, { useState, useRef } from "react";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import { removeError } from "actions/ui5";

import {
  Bar,
  Button,
  MessageViewButton,
  ValueState,
  ResponsivePopover,
  PopoverPlacementType,
  FlexBoxAlignItems,
  FlexBox,
  ButtonDesign,
  TitleLevel,
  Title,
  MessageView,
  MessageItem
} from "@ui5/webcomponents-react";

function MessageIndicator(props) {
  const { errorList, dispatch } = props;

  const viewButtonStyle = {};

  if (errorList.length === 0) {
    viewButtonStyle.display = "none";
  }

  const popoverRef = useRef(null);
  const messageViewRef = useRef(null);
  const [isOnDetailsPage, setIsOnDetailsPage] = useState(false);

  return (
    <div slot={props.slot}>
      <MessageViewButton
        type={ValueState.Information}
        counter={errorList.length}
        style={viewButtonStyle}
        onClick={event => {
          popoverRef.current.showAt(event.target);
        }}
      />
      {createPortal(
        <ResponsivePopover
          ref={popoverRef}
          placementType={PopoverPlacementType.Top}
          className="contentPartNoPadding headerPartNoPadding"
          style={{
            maxWidth: "30rem",
            minWidth: "20rem"
          }}
          header={
            <Bar
              startContent={
                <FlexBox alignItems={FlexBoxAlignItems.Center}>
                  {isOnDetailsPage && (
                    <Button
                      design={ButtonDesign.Transparent}
                      icon="slim-arrow-left"
                      onClick={() => {
                        setIsOnDetailsPage(false);
                        messageViewRef.current.navigateBack();
                      }}
                      style={{
                        marginInline: "0 0.5rem"
                      }}
                    />
                  )}
                  <Title level={TitleLevel.H5}>Messages</Title>
                </FlexBox>
              }
              endContent={
                <FlexBox alignItems={FlexBoxAlignItems.End}>
                  <Button
                    design={ButtonDesign.Transparent}
                    icon="delete"
                    onClick={() => {
                      dispatch(removeError());
                      popoverRef.current?.close();
                      setIsOnDetailsPage(false);
                      messageViewRef.current.navigateBack();
                    }}
                    style={{
                      marginInline: "0 0.5rem"
                    }}
                  />
                  <Button
                    design={ButtonDesign.Transparent}
                    icon="decline"
                    onClick={() => {
                      popoverRef.current?.close();
                      setIsOnDetailsPage(false);
                      messageViewRef.current.navigateBack();
                    }}
                    style={{
                      marginInline: "0 0.5rem"
                    }}
                  />
                </FlexBox>
              }
            />
          }
        >
          <MessageView
            ref={messageViewRef}
            showDetailsPageHeader={false}
            groupItems={false}
            onItemSelect={() => {
              setIsOnDetailsPage(true);
            }}
          >
            {errorList.map((msg, mIdx) => (
              <MessageItem
                key={mIdx}
                titleText={msg.message}
                subtitleText={msg.description}
                type={msg.type}
                // groupName={`Group${index}`}
              >
                {msg.description}
              </MessageItem>
            ))}
          </MessageView>
        </ResponsivePopover>,
        document.body
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    errorList: state.ui5.errorList
  };
}

export default connect(mapStateToProps)(MessageIndicator);
