import { tryit, byId } from "ui5-lib-rc";
import { addError } from "actions/ui5";
import getGlobalBusyDialog from "@bsgp/lib-api/busy_dialog";
import { asyncSignOut, updateExpiresAt } from "actions/user";

export const beforeEachProcess = function(process) {
  if (process.options.appIsBusy) {
    // byId("app").setBusyIndicatorDelay(0);
    // byId("app").setBusy(true);
    // window.sap.ui.core.BusyIndicator.show(0);

    try {
      getGlobalBusyDialog().open();
    } catch (ex) {
      setTimeout(() => {
        getGlobalBusyDialog().open();
      }, 1000);
    }
  }
};
export const beforeAllProcesses = function() {
  console.debug("beforeAllProcesses function not implemented.");
};
export const afterAllProcesses = function() {
  console.debug("afterAllProcesses function not implemented.");
  const bd = getGlobalBusyDialog();
  bd.close();
  byId("app").setBusy(false);
};

export const afterEachProcess = function(process, allProcesses) {
  const shouldDisableBusy = Object.keys(allProcesses)
    .filter(key => key !== process.processID)
    .reduce((acc, key) => {
      if (allProcesses[key].options.appIsBusy === true) {
        return false;
      }
      if (acc === false) {
        return acc;
      }
      return acc;
    }, true);
  if (shouldDisableBusy === true) {
    const bd = getGlobalBusyDialog();
    bd.close();
    byId("app").setBusy(false);
  }
};

function hasKeys(obj, keys) {
  const objKeys = Object.keys(obj);
  const result = keys.map(key => {
    return objKeys.find(objKey => objKey === key) ? true : false;
  });

  return result.findIndex(res => res === false) >= 0 ? false : true;
}

export const validateResponse = function(data, processItem) {
  return dispatch => {
    const ignoreError = tryit(() => processItem.ignoreError);
    if (ignoreError) {
      return;
    }

    const invalidAccess = tryit(() => data.body["invalid-access"]);
    if (invalidAccess) {
      dispatch(asyncSignOut());
    }

    const sessionExpiresAt = tryit(() => data.headers["expires-at"]);
    if (sessionExpiresAt) {
      dispatch(updateExpiresAt(sessionExpiresAt));
    }

    if (hasKeys(data, ["message", "connectionId", "requestId"])) {
      dispatch(
        addError({
          message: data.message,
          description: JSON.stringify(data)
        })
      );
      return true;
    }

    const exceptionError = tryit(() => {
      return data.body.Exception;
    });
    if (!!exceptionError) {
      dispatch(
        addError({
          message: "" + data.statusCode + " Client Error",
          description: exceptionError
        })
      );
      return true;
    }

    const errorMessage = tryit(() => {
      if (data.body.codeString) {
        return [`[${data.body.name}]`, data.body.key, data.body.errorMessage]
          .filter(Boolean)
          .join(" ");
      }
      return data.body.errorMessage;
    });
    if (!!errorMessage) {
      console.error(errorMessage);
      dispatch(
        addError({
          message: errorMessage,
          description:
            tryit(() => data.body.errorDescription) ||
            tryit(() => data.body.stack) ||
            ""
        })
      );
      return true;
    }

    const hasHttpError500_400 = tryit(() => {
      return (
        data.statusCode >= 400 && data.statusCode <= 599 && !!data.body.message
      );
    });
    if (hasHttpError500_400) {
      dispatch(
        addError({
          message: data.body.message,
          description: data.body.message
        })
      );
      return true;
    }

    // const hasEmptyHttpResponse = tryit(() => {
    //   return data.statusCode === 204 && !data.body;
    // });
    // if (hasEmptyHttpResponse) {
    //   dispatch(
    //     addError(
    //       "빈 결과값이 감지되었습니다. 예기치 않은 오류일 경우 조속히 수정조치하겠습니다 (Status:204)"
    //     )
    //   );
    //   return true;
    // }

    const soapError = tryit(() => {
      return data.soap_error || [];
    }, []);
    soapError.forEach(err => {
      dispatch(
        addError(
          convertStackTrace(err.message, err.stackTrace, err.description)
        )
      );
    });
    if (soapError.length > 0) {
      return true;
    }

    const exceptionMessage = tryit(() => {
      return data.ExceptionMessage;
    });
    if (exceptionMessage && exceptionMessage.length > 0) {
      dispatch(
        addError({
          message: data.action + "-" + data.subAction + " has exception message"
        })
      );
      return true;
    }

    const responseLog = tryit(() => {
      const keys = Object.keys(data);
      if (keys.find(key => key === "ExceptionMessage")) {
        const messageList = [];
        const responseKey = keys.find(key => Array.isArray(data[key]));
        data[responseKey].forEach(res => {
          const resKeys = Object.keys(res);
          const logKey = resKeys.find(key => Array.isArray(res[key]));
          const logs = res[logKey];
          logs.forEach(log => {
            if (hasKeys(log, ["NodeID", "NodeName", "Note", "SeverityCode"])) {
              messageList.push({
                type: log.SeverityCode,
                message: log.SeverityCode + " " + log.NodeName,
                description: log.Note
              });
            }
          });
        });
        return messageList;
      } else {
        return [];
      }
    }, []);
    const errorList = responseLog.reduce((result, log) => {
      log.type === "E" && result.push(log);
      return result;
    }, []);
    if (responseLog.length > 0) {
      dispatch(addError(responseLog));
    }
    if (errorList.length > 0) {
      return true;
    }

    const soapLog = tryit(() => {
      const keys = Object.keys(data);
      if (keys.find(key => key === "Log")) {
        const messageList = [];
        const resKeys = Object.keys(data.Log);
        const logKey = resKeys.find(key => Array.isArray(data.Log[key]));
        const logs = data.Log[logKey];
        logs.forEach(log => {
          if (
            hasKeys(log, ["CategoryCode", "TypeID", "Note", "SeverityCode"])
          ) {
            if (Number(log.SeverityCode) === 3) {
              messageList.push({
                type: "E",
                message:
                  "SeverityCode:" + log.SeverityCode + " TypeID:" + log.TypeID,
                description: log.Note
              });
            }
          }
        });
        return messageList;
      } else {
        return [];
      }
    }, []);
    if (soapLog.length > 0) {
      dispatch(addError(soapLog));
    }
  };
};

function convertStackTrace(message, stackTrace, description) {
  const result = {
    is_markup: true,
    message: message,
    description:
      stackTrace &&
      stackTrace
        .map(st => {
          const markup = ["<div>"];
          if (typeof st === "string") {
            markup.push(
              ...[
                `<p style=${"font-size:0.8rem;line-height:0.8rem;"}>`,
                st,
                `</p>`
              ]
            );
          } else {
            markup.push(
              ...st.map((std, idx) => {
                if ([1, 2].filter(each => idx === each).length > 0) {
                  return "";
                }

                const lineNumber = tryit(() => {
                  return idx === 0 ? st[1] : 0;
                }, 0);
                const inMethod = tryit(() => {
                  return idx === 0 ? st[2] : 0;
                }, 0);

                return [
                  `<p style=${
                    idx === 0
                      ? "font-weight:bold;"
                      : "font-size:0.8rem;line-height:0.5rem;"
                  }>`,
                  lineNumber > 0
                    ? `${std} - (line ${lineNumber} in ${inMethod})`
                    : std,
                  "</p>"
                ].join("");
              })
            );
          }
          markup.push("</div>");
          return markup.join("");
        })
        .join("")
  };

  if (!result.description) {
    result.description = tryit(
      () => description.StandardFaultMessage.standard.faultText,
      ""
    );
  }

  return result;
}
